const projects = {
  middleware: {
    title: "Middleware UIoT",
    subtitle: "Short description",
    bind: "middleware",
    projects: [
      {
        name: "Gateway",
        icon: "mdi-router-wireless",
        color: "#94a69c",
        text_color: "black--text",
        text: "gateway",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-language-python",
            name: "python"
          },
          {
            language: "mdi-database",
            name: "mongodb"
          }
        ]
      },
      {
        name: "DIMS",
        icon: "mdi-database",
        color: "blue-grey lighten-2",
        text_color: "black--text",
        text: "DIMS",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-language-python",
            name: "python"
          },
          {
            language: "mdi-database",
            name: "mongodb"
          }
        ]
      },
      {
        name: "UIMS",
        icon: "mdi-chart-line",
        color: "#c1c2d9",
        text_color: "black--text",
        text: "UIMS",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-language-javascript",
            name: "javascript"
          },
          {
            language: "mdi-database",
            name: "mongodb"
          }
        ]
      },
      {
        name: "Dashboard UIoT",
        icon: "mdi-view-dashboard",
        color: "#4b4d54",
        text_color: "white--text",
        text: "dashboard",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-language-javascript",
            name: "vuejs"
          },
          {
            language: "mdi-database",
            name: "postgresql"
          },
          {
            language: "mdi-language-html5",
            name: "html"
          }
        ]
      }
    ]
  },
  covid: {
    title: "Amoris",
    subtitle: "Short description",
    bind: "amoris",
    projects: [
      {
        name: "Dashboard Covid",
        icon: "mdi-view-dashboard",
        color: "#e8dab3",
        short_description: "The easiest way to interact with your devices",
        text: "dashboard",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-language-javascript",
            name: "javascript"
          },
          {
            language: "mdi-language-html5",
            name: "html"
          }
        ]
      },
      {
        name: "UnB Solidária",
        icon: "mdi-cellphone",
        color: "#b3dbe8",
        text_color: "black--text",
        text: "solidaria",
        img: "bla",
        link: "abc",
        technologies: [
          {
            language: "mdi-android",
            name: "android"
          },
          {
            language: "mdi-database",
            name: "postgresql"
          }
        ]
      }
    ]
  }
};

export default projects;
