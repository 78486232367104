const Languages = {
  English: {
    welcomeMsg: "The universal way of connecting things!",
    lorenIpsum:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ante elit, sagittis ac justo vitae, venenatis varius mi. Mauris ultrices magna vel risus lacinia, non varius velit tempus. Sed eget odio magna. Proin tincidunt eros id enim consequat, ac volutpat ante lobortis. Sed efficitur nisl tristique, scelerisque leo id, suscipit ipsum. Integer hendrerit porttitor ante at egestas. Donec tincidunt justo ligula, quis tempus ipsum lobortis sed. Aenean in augue rutrum elit elementum luctus. Morbi nisl urna, facilisis eget sodales vitae, faucibus non mauris. Vestibulum et orci varius, posuere quam a, mollis ipsum. ",
    footer:
      "The UnB Internet of Things (UIoT) project is maintained by the Decision Making Laboratory (LATITUDE) and is located at the Faculty of Technology (FT) in the Department of Electrical Engineering of the University of Brasília (UnB)",
    toolbar: {
      projects: "Projects",
      publications: "Publications",
      team: "Team",
      docs: "Docs"
    },
    statusMember: "Saíram",
    wwdo: {
      title: "This is what we do",
      text:
        "UIoT is a scientific research laboratory in applications involving Internet of Things (IoT). Here we develop projects that involve a complete solution, involving the system of these devices (software), the physical part (hardware) and also information security",
      hardware: {
        title: "Hardware",
        text:
          "The hardware area of ​​the laboratory is responsible for developing physical devices that have the function of collecting data from the environment. Here we use the most diverse technologies, such as Raspbery's, arduinos, monitoring sensors, protocols such as ** HTTP **, ** LoRA **, among many others."
      },
      software: {
        title: "Software",
        text:
          "They are responsible for creating software IoT solutions and developing all the necessary infrastructure to collect data from physical devices, store them, process them and deliver a quality visualization / interaction to the user."
      },
      security: {
        title: "Security",
        text:
          "This is the team responsible for creating solutions that allow secure end-to-end data traffic."
      },
      button: "Check out"
    },
    home: {
      academics: {
        title: "UIoT Publications",
        text:
          "Ours publications not involve only IoT topics. We research new technologies involving..."
      }
    },
    team: {
      title: "Our Team",
      text:
        "Our team is composed of students from several courses at the University of Brasília (UnB), such as: Communication Network Engineering, Electrical Engineering, Computer Engineering, Production Engineering, among others. It is also composed of students from other colleges."
    },
    pages: {
      publications: {
        title: "UIoT Publications",
        subtitle: "Checkout our academics publications",
        publicationYear: "Publication Year"
      },
      projects: {
        title: "UIoT Projects",
        subtitle: "Checkout our projects",
        middleware: {
          shortdescription: "This is an description of middleware IoT",
          gateway:
            "The UIoT Gateway is the architecture instance responsible for the communication between the physical devices and the UIoT middleware",
          DIMS:
            "DIMS is an instance of UIoT responsible for storing data from physical devices persistently",
          UIMS:
            "UIMS is the graphic module that communicates with DIMS for data visualization, here it is possible to filter the data of interest and visualize it in the form of graphics",
          dashboard:
            "The Dashboard is the data visualization module of the database but in a contextual way. It is possible to create scenarios of interest and include devices, as well as interact with them, by sending commands"
        },
        amoris: {
          dashboard:
            "The covid Dashboard is a project developed by the UIoT laboratory to combat the pandemic caused by Covid 19. This monitoring dashboard is able to analyze the data sent by users and develop geolocalized health actions",
          solidaria:
            "Application developed to assist in the safety of students at the University of Brasilia (UnB). With this application, a student is able to report to university security moments that need help, such as emergency alerts, escorts or health aids"
        }
      },
      team: {
        title: "UIoT Team",
        subtitle: "Our team"
      }
    }
  },
  Portugues: {
    welcomeMsg: "A maneira universal de conectar as coisas!",
    ecosystem: "Ecossistema",
    footer:
      "O projeto UnB Internet of Things (UIoT) é mantido pelo Laboratório de Tomada de Decisão (LATITUDE) e está localizado na Faculdade de Tecnologia (FT) no departamento de Engenharia Elétrica da Universidade de Brasília (UnB)",
    toolbar: {
      projects: "Projetos",
      publications: "Publicações",
      team: "Time",
      docs: "Docs"
    },
    statusMember: "Saíram",
    wwdo: {
      title: "Isso é o que nós fazemos",
      text:
        "O UIoT é um laboratório de pesquisa científica em aplicações que envolvem Internet das Coisas (IoT). Aqui desenvolvemos projetos que envolvem a solução completa, envolvendo o sistema destes dispositivos (software), a parte física (hardware) e também a segurança da informação",
      hardware: {
        title: "Hardware",
        text:
          "A área de hardware do laboratório é responsável por desenvolver dispositivos físicos que têm a função de coletar dados do ambiente. Aqui utilizamos as mais diversas tecnologias, como Raspbery's, arduinos, sensores de monitoramento, protocolos como HTTP, LoRA, entre muitos outros"
      },
      software: {
        title: "Software",
        text:
          "São responsáveis por criar soluções IoT em software e desenvolver toda a infraestrutura necessária para coletar os dados dos dispositivos físicos, armazená-los, processá-los e entregar uma visualização/interação de qualidade para o usuário."
      },
      security: {
        title: "Segurança",
        text:
          "Esta é a equipe responsável por criar soluções que permitem um tráfego de dados seguro de ponta a ponta"
      },
      button: "Confira"
    },
    home: {
      academics: {
        title: "Publicações do UIoT",
        text:
          "Nossas publicações não envolvem apenas tópicos de IoT. Pesquisamos novas tecnologias envolvendo ..."
      }
    },
    team: {
      title: "Nosso time",
      text:
        "Nosso time é composto por alunos de diversos cursos da Universidade de Brasília (UnB), dentre eles: Engenharia de Redes de Comunicação, Engenharia Elétrica, Engenharia da Computação, Engenharia de Produção, entre outros. Também é composto por alunos de outros faculdades."
    },
    pages: {
      publications: {
        title: "Publicações do UIoT",
        subtitle: "Confira nossas publicações acadêmicas",
        publicationYear: "Ano de publicação"
      },
      projects: {
        title: "Projetos do UIoT",
        subtitle: "Confira nossos projetos",
        middleware: {
          shortdescription: "This is an description of middleware IoT",
          gateway:
            "O Gateway UIoT é a instância da arquitetura responsável pela comunicação entre entre os dispositivos físicos e o middleware UIoT",
          DIMS:
            "O DIMS é uma instância do UIoT responsável pelo armazenamento de dados dos dispositivos físicos de forma persistente",
          UIMS:
            "O UIMS é o módulo gráfico que se comunica com o DIMS para visualização de dados, aqui é possível filtrar os dados de interesse e visualizar em forma de gráficos",
          dashboard:
            "O Dashboard é o módulo de visualização de dados do banco de dados mas de forma contextual. É possível criar cenários de interesse e incluir dispositivos, assim como interagir com os mesmos, enviando comandos"
        },
        amoris: {
          dashboard:
            "O Dashboard covid é um projeto desenvolvido pelo laboratório UIoT para combater a pandemia causada pelo Covid 19. Este dashboard de monitoramento é capaz de analisar os dados enviados pelos usuários e desenvolver ações de saúde geolocalizadas",
          solidaria:
            "Aplicativo desenvolvido para auxiliar na segurança dos alunos da Universidade de Brasília (UnB). Com este aplicativo, um aluno consegue reportar para a segurança da universidade momentos que necessite de ajuda, como alertas de emergência, escolta ou ajudas de saúde."
        }
      },
      team: {
        title: "Time UIoT",
        subtitle: "Nosso time"
      }
    }
  }
};

export default Languages;
