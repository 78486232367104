const Publications = [
  //2023
  {
    year: 2023,
    title:
      "HIDS distribuído expans JNIC 2019",
    authors: [
      "franciscocaldasf",
      "samuelalmeida",
      "robsonalbuquerde",
      "fabiomendonca"
    ],
    type: "journal",
    name: "",
    short: "IEEE Access",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  }, 
  
  //2022
  {
    year: 2022,
    title:
      "Meta-Semantic Search Engine Method Proposition for Transparent Decision Auditing",
    authors: [
      "franciscocaldasf",
    ],
    type: "conference",
    name: "",
    short: "ICSBT",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  }, 
  {
    year: 2022,
    title:
      "Sistema para a identificação de aglomerações operando em Redes IoT e Fog Computing",
    authors: [
      "brunodias",
      "franciscocaldasf",
      "ianmello",
      "fabiomendonca",
      "rafaelzerbini",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "RISTI",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },  

  //2013 -2021
  {
    year: 2021,
    title:
      "Design and Evaluation of a Data Collector and Analyzer to Monitor the COVID-19 and other Epidemic Outbreaks",
    authors: [
      "lucascoelho",
      "franciscocaldasf",
      "nataliamarques",
      "fabiomendonca",
      "prado",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "ICITS",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },  
  
  {
    year: 2021,
    title:
      "Design of a Fog Controller to Provide an IoT Middleware with Hierarchical Interaction Capability",
    authors: [
      "prado",
      "franciscocaldasf",
      "lucascoelho",
      "lucasmartins",
      "nataliamarques",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "ICITS",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },   
  
  {
    year: 2021,
    title:
      "Home Office file-sharing: A secure and highly available file storage solution in a private cloud environment",
    authors: [
      "rafaelmonteiro",
      "franciscocaldasf",
      "robsonalbuquerde",
      "lucasmartins",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "ICITS",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  }, 
  
  {
    year: 2021,
    title:
      "Design of an Interactive Mobile Platform to Assist Communities in Voluntary Cooperation to Counter the Covid-19 Outbreak (AMORISIOT Mobile APP)",
    authors: [
      "franciscocaldasf",
      "nayarasilva",
      "leonardoalmeida",
      "paulomendes",
      "andrecavalcanti",
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "WorldCIST",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },   
  
  {
    year: 2021,
    title:
      "Data Science Procedures to Aggregate Unstructured Disease Data in Georeferenced Spreading Analysis",
    authors: [
      "lucascoelho",
      "franciscocaldasf",
      "nataliamarques",
      "lucasmartins",
      "prado",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "WorldCIST",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },    
  
  {
    year: 2021,
    title:
      "Design and Implementation of a Collaborative Platform Model for Epidemic and Collective Health Services",
    authors: [
      "lucascoelho",
      "prado",
      "lucasmartins",      
      "franciscocaldasf",
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "IJWI",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },  
  
  {
    year: 2021,
    title:
      "Distributed Architecture for Intrusion Detection inIoT Network using Smart Contracts",
    authors: [
      "rafaelzerbini",
      "franciscocaldasf",
      "lucasmartins",      
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "JNIC",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },    
  
  {
    year: 2021,
    title:
      "Solid waste management and monitoringsystem for smart cities: development of alow-cost sustainable IoT architecture usingGPRS/GSM",
    authors: [
      "matheusmonteiro",
      "franciscocaldasf",
      "pedrosusa",
      "viniciuscosta",
      "gustavoluz",      
      "luiscarvalho",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "WCNPS",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },  
  
  {
    year: 2021,
    title:
      "Hybrid Architecture for Intrusion Prevention and Detection in IoT Networks",
    authors: [
      "rafaelzerbini",
      "franciscocaldasf",
      "fabiomendonca",
      //"awatef",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "WCNPS",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },
  
  {
    year: 2021,
    title:
      "Sistema para a identificação de aglomerações operando em Redes IoT e Fog Computing",
    authors: [
      "brunodias",
      "ianmello",
      "franciscocaldasf",
      "leonardoalmeida",
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "ICITS",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },  

  {
    year: 2021,
    title:
      "Sistema de Internet das Coisas para Monitoração de Dados e Tomada de Decisões Quanto à Coleta Seletiva de Lixo em Cidades Inteligentes.",
    authors: [
      "gustavoluz",
      "lucasdanelon",
      "luiscarvalho",
      "viniciuscosta",
      "desousa"
     
    ],
    type: "conference",
    name: "",
    short: "CIACA",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },

  {
    year: 2021,
    title:
      "Sistema Monitor de Aglomerações Baseado em Reconhecimento de Padrões e Cálculos de Distanciamento Social Operante em Rede IoT Estruturada em Fog Computing",
    authors: [
      "brunodias",
      "ianmello",
      "franciscocaldasf",
      "rafaelzerbini",
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "CIACA",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },  



  

  
  
  {
    year: 2020,
    title:
      "Projeto e Implementação de uma Plataforma Colaborativa para Auxiliar a Comunidade Acadêmica com Problemas Ocasionados pela Pandemia do Covid-19",
    authors: [
      "franciscocaldasf",
      "nataliamarques",
      "leonardoalmeida",
      "paulomendes",
      "andrecavalcanti",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "CIACA",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },  
  {
    year: 2020,
    title:
      "Revista:HIDS distribuído expans JNIC 2019",
    authors: [
      "franciscocaldasf",
      "rafaelzerbini",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "IEEE Acces",
    publication_link: "",
    download: "",
    language: "en",
    published: true
  },
  {
    year: 2020,
    title:
      "Composição de Gateways IoT Redundantes com Base em Computação de Nevoeiro",
    authors: [
      "paulomascarenhas",
      "franciscocaldasf",
      "cassioribeiro",
      "fabiomendonca",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "SBrT",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },   
  {
    year: 2020,
    title:
      "Vulnerabilidades de sistemas ciber-físicos ",
    authors: [
      "mateuszanatta",
      "lustosa",
      "franciscocaldasf",
      "fabiomendonca",
      "danielsilva",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "CIACA",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },   
  {
    year: 2020,
    title:
      "Projeto e Implementação de um Sistema de Visualização de Dados de Epidemias Obtidos Colaborativamente",
    authors: [
      "lucascoelho",
      "prado",
      "lucasmartins",
      "jonathasalves",
      "danielsilva",
      "desousa"
    ],
    type: "conference",
    name: "",
    short: "CIACA",
    publication_link: "",
    download: "",
    language: "pt-br",
    published: true
  },   
  {
      year: 2020,
      title:
        "IoT Fog-based image matching monitoring systemfor physical access control through IP camera devices",
      authors: [
        "marcosandrade",
        "fernandaformiga",
        "lucasmartins",
        "hectoroliveira",
        "fabiomendonca",
        "desousa"
      ],
      type: "conference",
      name: "",
      short: "WCNPS",
      publication_link: "",
      download: "",
      language: "en",
      published: true
    },  
    {
      year: 2020,
      title:
        "Technological Solution Development During the COVID-19 Pandemic: a Case Study in an IoT Lab",
      authors: [
        "patrao",
        "franciscocaldasf",
        "gustavoluz",
        "carolinasartori",
        "fabiomendonca",
        "desousa"
      ],
      type: "conference",
      name: "",
      short: "GCAIoT 2020",
      publication_link: "",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2020,
      title:
        "Optimization Model for an Individualized IoT Ambient Monitoring and Control System",
      authors: [
        "patrao",
        "lucasmartins",
        "franciscocaldasf",
        "marcosandrade",
        "fernandaformiga",
        "desousa"
      ],
      type: "conference",
      name: "",
      short: "GCAIoT 2020",
      publication_link: "",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2020,
      title:
        "Monitoramento e controle de ambientes com base em aprendizagem de máquina e computação em nevoeiro em arquitetura IoT",
      authors: [
        "patrao",
        "franciscocaldasf",
        "lucasmartins",
        "gersonsilva",
        "matheusmonteiro",
        "marcosandrade",
        "fabiomendonca",
        "desousa"
      ],
      type: "conference",
      name: "15th Iberian Conference on Information Systems and Technologies",
      short: "CISTI 2020",
      publication_link: "https://ieeexplore.ieee.org/abstract/document/9141085",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2019,
      title: "A Real Data Analysis in an Internet of Things Environment",
      authors: [
        "poletti",
        "lucasmartins",
        "samuelalmeida",
        "maristelaholanda",
        "desousa"
      ],
      type: "conference",
      name:
        "4th International Conference on Internet of Things, Big Data and Security",
      short: "IoTBDS 2019",
      publication_link:
        "https://www.scitepress.org/PublicationsDetail.aspx?ID=2Wb+aNVirL0=&t=1",
      download: "polettietal2019.pdf",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title: "HIDS by Signature for embedded devices in IoT networks",
      authors: [
        "brunodutra",
        "fiuza",
        "franciscocaldasf",
        "lucasmartins",
        "desousa",
        "robsonalbuquerde"
      ],
      type: "conference",
      name: "V Jornadas Nacionales de Investigación en Ciberseguridad",
      short: "JNIC 2019",
      publication_link: "https://2019.jnic.es/Actas_JNIC2019.pdf",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title:
        "P2PIoT: A Peer-To-Peer Communication Model for the Internet of Things",
      authors: [
        "fabiomendonca",
        "dayannecunha",
        "justino",
        "mateuszanatta",
        "lustosa",
        "desousa"
      ],
      type: "conference",
      name: "2019 Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2019",
      publication_link: "https://ieeexplore.ieee.org/document/8896313",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title: "IPS architecture for IoT networks overlapped in SDN",
      authors: [
        "venzi",
        "brunodutra",
        "lucasmartins",
        "franciscocaldasf",
        "kfouri",
        "robsonalbuquerde",
        "desousa"
      ],
      type: "conference",
      name: "2019 Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2019",
      publication_link: "https://ieeexplore.ieee.org/document/8896297",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title: "University Campus Microclimate Monitoring Using IoT",
      authors: [
        "matheusmonteiro",
        "lucasmartins",
        "franciscocaldasf",
        "lincolnbarbosa",
        "tribouilet",
        "demetriosilvaf"
      ],
      type: "conference",
      name: "2019 Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2019",
      publication_link: "https://ieeexplore.ieee.org/document/8896242",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title: "QoS Scheduling Algorithm for a Fog IoT Gateway",
      authors: [
        "lucasmartins",
        "franciscocaldasf",
        "rodrigorocha",
        "barenco",
        "ednacanedo",
        "desousa"
      ],
      type: "conference",
      name: "2019 Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2019",
      publication_link: "https://ieeexplore.ieee.org/document/8896311",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title:
        "Proposal of an Adaptable and Scalable IoT Middleware for Hybrid Computational Models",
      authors: ["lucasmartins"],
      type: "master thesis",
      name: "Universidade de Brasília",
      short: "UnB",
      publication_link: "https://repositorio.unb.br/handle/10482/38655",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title:
        "Proposta de um Gateway IoT em Computação Fog com Técnicas de Aceleração WAN",
      authors: ["franciscocaldasf"],
      type: "master thesis",
      name: "Universidade de Brasília",
      short: "UnB",
      publication_link: "https://repositorio.unb.br/handle/10482/38117",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2019,
      title:
        "Inference of driver behavior using correlated IoT data from the vehicle telemetry and the driver mobile phone",
      authors: [
        "danielsilva",
        "josetorres",
        "alexandrepinheiro",
        "franciscocaldasf",
        "fabiomendonca",
        "justino",
        "kfouri",
        "desousa"
      ],
      type: "conference",
      name:
        "14th Federated Conference on Computer Science and Information Systems",
      short: "FedCSIS 2019",
      publication_link: "https://fedcsis.org/proceedings/2019/drp/263.html",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2019,
      title: "Arquitetura de IPS para redes IoT sobrepostas em SDN",
      authors: [
        "venzi",
        "kfouri",
        "brunodutra",
        "fiuza",
        "franciscocaldasf",
        "lucasmartins",
        "robsonalbuquerde",
        "desousa"
      ],
      type: "conference",
      name:
        "XIX Simpósio Brasileiro de Segurança da Informação e de Sistemas Computacionais",
      short: "SBSeg 2019",
      publication_link: "https://sbseg2019.ime.usp.br/artigos.html",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2019,
      title: "Design of a Distributed HIDS for IoT Backbone Components",
      authors: [
        "kfouri",
        "venzi",
        "brunodutra",
        "fiuza",
        "franciscocaldasf",
        "lucasmartins",
        "justino",
        "robsonalbuquerde",
        "desousa"
      ],
      type: "conference",
      name:
        "14th Federated Conference on Computer Science and Information Systems",
      short: "FedCSIS 2019",
      publication_link: "https://fedcsis.org/proceedings/2019/drp/329.html",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2018,
      title:
        "Evaluation of an IoT device designed for transparent traffic analysis",
      authors: [
        "thalessperling",
        "brunofranca",
        "franciscocaldasf",
        "lucasmartins",
        "desousa",
        "robsonalbuquerde"
      ],
      type: "conference",
      name: "3rd Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2018",
      publication_link: "https://ieeexplore.ieee.org/abstract/document/8604444",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2018,
      title: "Protocolos de Redundancia de Gateway Aplicados em Redes IoT",
      authors: [
        "cassioribeiro",
        "franciscocaldasf",
        "lucasmartins",
        "barenco",
        "desousa"
      ],
      type: "conference",
      name:
        "XXXVI Simpósio Brasileiro de Telecomunicações e Processamento de Sinais",
      short: "SBrT 2018",
      publication_link: "",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2017,
      title:
        "Tracking intruders in IoT networks by means of DNS traffic analysis",
      authors: [
        "thalessperling",
        "franciscocaldasf",
        "lucasmartins",
        "desousa",
        "rodrigorocha"
      ],
      type: "conference",
      name: "2nd Workshop on Communication Networks and Power Systems",
      short: "WCNPS 2017",
      publication_link: "https://ieeexplore.ieee.org/document/8252938",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2017,
      title:
        "Increasing the Dependability of IoT Middleware with Cloud Computing and Microservices",
      authors: [
        "franciscocaldasf",
        "lucasmartins",
        "desousa",
        "giozza",
        "lustosa"
      ],
      type: "conference",
      name: "International Conference on Utility and Cloud Computing 2017",
      short: "UCC/BDCAT 2017",
      publication_link: "https://dl.acm.org/doi/10.1145/3147234.3148092",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2017,
      title:
        "Design and Evaluation of a Semantic Gateway Prototype for IoT Networks",
      authors: [
        "franciscocaldasf",
        "lucasmartins",
        "desousa",
        "ingridaraujo",
        "lustosa",
        "fabiomendonca"
      ],
      type: "conference",
      name: "International Conference on Utility and Cloud Computing 2017",
      short: "UCC/BDCAT 2017",
      publication_link: "http://doi.acm.org/10.1145/3147234.3148091",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2017,
      title:
        "Distributed Data Service for Data management in Internet of Things Middleware",
      authors: [
        "rubenhuacarpuma",
        "maristelaholanda",
        "desousa",
        "robsonalbuquerde",
        "luisvillalba",
        "taikim"
      ],
      type: "journal",
      name: "Sensors",
      short: "Sensors",
      publication_link: "http://www.mdpi.com/1424-8220/17/5/977",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2017,
      title:
        "Proposição de um modelo e sistema de gerenciamento de dados distribuídos para internet das coisas - GDDIoT",
      authors: ["rubenhuacarpuma"],
      type: "doctoral thesis",
      name: "Universidade de Brasília",
      short: "UnB",
      publication_link: "https://repositorio.unb.br/handle/10482/31146",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2017,
      title: "Security analysis of a proposed internet of things middleware",
      authors: ["hiroferreira", "desousa"],
      type: "journal",
      name: "Cluster Computing",
      short: "Cluster Comput",
      publication_link: "http://link.springer.com/10.1007/s10586-017-0729-3",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2017,
      title: "Proposta de adoção de microsserviços em IoT",
      authors: [
        "lucasmartins",
        "franciscocaldasf",
        "desousa",
        "giozza",
        "lustosa"
      ],
      type: "conference",
      name: "Conferência Ibero-Americana WWW/Internet 2017",
      short: "CIAWI 2017",
      publication_link:
        "http://www.iadisportal.org/digital-library/proposta-de-ado%C3%A7%C3%A3o-de-microsservi%C3%A7os-em-iot",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2017,
      title: "Gerenciamento de Serviços IoT com Gateway Semântico",
      authors: [
        "lucasmartins",
        "franciscocaldasf",
        "desousa",
        "ingridaraujo",
        "fabiomendonca",
        "lustosa"
      ],
      type: "conference",
      name: "Conferências Ibero-Americanas de Computação Aplicada 2017",
      short: "CIACA 2017",
      publication_link:
        "http://www.iadisportal.org/digital-library/gerenciamento-de-servi%C3%A7os-iot-com-gateway-sem%C3%A2ntico",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2016,
      title:
        "Designand Evaluation of a Services Interface for the Internet of Things",
      authors: [
        "caiosilva",
        "hiroferreira",
        "desousa",
        "fabiobuiati",
        "luisvillalba"
      ],
      type: "journal",
      name: "Wireless Personal Communications",
      short: "Wireless Pers Commum",
      publication_link:
        "https://link.springer.com/article/10.1007%2Fs11277-015-3168-6",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2016,
      title:
        "Proposta de auto-registro de serviços pelos dispositivos em ambientes de IoT",
      authors: [
        "caiosilva",
        "franciscocaldasf",
        "felipemachado",
        "fabiomendonca",
        "desousa"
      ],
      type: "conference",
      name:
        "XXXIV Simpósio Brasileiro de Telecomunicações e Processamento de Sinais",
      short: "SBrT",
      publication_link: "http://sbrt.org.br/sbrt2016/anais/",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2016,
      title:
        "Concepção e Desenvolvimento de um Serviço Distribuido de Coleta e Tratamento de Dados para Ambientes de Internet das Coisas",
      authors: [
        "rubenhuacarpuma",
        "desousa",
        "maristelaholanda",
        "sergiolifschitz"
      ],
      type: "conference",
      name: "31o Simpósio Brasileiro de Banco de Dados",
      short: "SBBD 2016",
      publication_link: "http://sbbd2016.fpc.ufba.br/artigos-aceitos/",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2014,
      title:
        "A ubiquitous communication architecture integrating trasparent UPnP and REST APIs",
      authors: ["hiroferreira", "ednacanedo", "desousa"],
      type: "journal",
      name: "International Journal of Embedded Systems",
      short: "IJES",
      publication_link: "http://www.inderscience.com/link.php?id=63816",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2014,
      title: "Arquitetura de Middleware para Internet das Coisas",
      authors: ["hiroferreira"],
      type: "master thesis",
      name: "Universidade de Brasília",
      short: "UnB",
      publication_link: "https://repositorio.unb.br/handle/10482/17251",
      download: "",
      language: "pt-br",
      published: true
    },
    {
      year: 2014,
      title:
        "Proposal of a Secure, Deployable and Transparent Middleware for Internet of Things",
      authors: ["hiroferreira", "desousa", "flavioelias", "ednacanedo"],
      type: "conference",
      name: "9th Iberian Conference on Information Systems and Technologies",
      short: "CISTI 2014",
      publication_link: "https://ieeexplore.ieee.org/document/6877069",
      download: "",
      language: "en",
      published: true
    },
    {
      year: 2013,
      title:
        "IoT Architecture to Enable Intercommunication Through REST API and UPnP Using IP, ZigBee and Arduino",
      authors: ["hiroferreira", "ednacanedo", "desousa"],
      type: "conference",
      name:
        "IEEE 9th International Conference on Wireless and Mobile Computing, Networking and Communications",
      short: "WiMob 2013",
      publication_link: "https://ieeexplore.ieee.org/document/6673340/",
      download: "",
      language: "en",
      published: true
    }
  ];
  
  export default Publications;
  
