var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"style-top base lighten-1"}),_c('v-toolbar',{staticClass:"grey lighten-3",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pl-10"},[_c('div',{on:{"click":function($event){return _vm.goTo()}}},[_c('v-img',{staticClass:"nameLogo",attrs:{"src":require("@/assets/logo.png"),"width":"35"}}),_c('span',{staticClass:"titleText  nameLogo base--text"},[_c('h2',[_c('b',[_vm._v(" uiot ")])])])],1),_c('div')]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"pr-10"},[_c('v-btn',{class:{
          base: _vm.checkRouteColor('projects', _vm.currentRoute),
          'lighten-2': true
        },attrs:{"text":"","to":"/projects"}},[_vm._v(" "+_vm._s(_vm.$t("toolbar.projects"))+" ")]),_c('v-btn',{class:{
          base: _vm.checkRouteColor('publications', _vm.currentRoute),
          'lighten-2': true
        },attrs:{"text":"","to":"/publications"}},[_vm._v(" "+_vm._s(_vm.$t("toolbar.publications"))+" ")]),_c('v-btn',{class:{
          base: _vm.checkRouteColor('team', _vm.currentRoute),
          'lighten-2': true
        },attrs:{"text":"","to":"/team"}},[_vm._v(" "+_vm._s(_vm.$t("toolbar.team"))+" ")]),_c('v-btn',{class:{
          base: _vm.checkRouteColor('docs', _vm.currentRoute),
          'lighten-2': true
        },attrs:{"text":"","to":"/docs"}},[_vm._v(" DOCS ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.currentLang)+" ")])]}}])},[_c('v-list',_vm._l((_vm.languages),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{staticClass:"botao",attrs:{"text":""},on:{"click":function($event){return _vm.changeLocale(item.language)}}},[_c('v-img',{attrs:{"width":"30","src":require(("@/assets/" + (item.img)))}}),_c('v-span',{staticClass:"lang-title"},[_vm._v(" - "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }