var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"grey lighten-5",attrs:{"headers":_vm.headers,"items":!_vm.conference
        ? _vm.data
        : _vm.data.filter(function(item) {
            return item.short == _vm.conference;
          }),"item-key":"title"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:(i + "-" + (item.id))},[_c('td',[_c('v-chip',{staticClass:"base white--text"},[_c('b',[_vm._v(" "+_vm._s(item.year)+" ")])])],1),(!item.language)?_c('td'):(item.language === 'en')?_c('td',[_c('img',{attrs:{"width":"20","src":require("@/assets/united-states.png")}})]):(item.language === 'pt-br')?_c('td',[_c('img',{attrs:{"width":"20","src":require("@/assets/brazil.png")}})]):_c('td'),_c('td',[_vm._v(" "+_vm._s(item.title)+" "),(!item.published)?_c('span',{staticClass:"highlight"},[_c('br'),_vm._v(" «not published yet» ")]):_vm._e()]),_c('td',[_c('span',[_vm._v(_vm._s(item.short))]),(!item.short)?_c('span',[_vm._v("("+_vm._s(item.type)+")")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm._f("getAuthorsNames")(item.authors)))]),_c('td',[(item.download)?_c('a',{attrs:{"href":("/assets/pdf/" + (item.download)),"target":"_blank"}},[_c('img',{attrs:{"width":"20","src":require("@/assets/pdf.png")}})]):_vm._e(),(item.publication_link)?_c('a',{attrs:{"href":("" + (item.publication_link)),"target":"_blank"}},[_c('img',{attrs:{"width":"20","src":require("@/assets/globe.png")}})]):_vm._e()])])}),0)]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return _vm._l((item.authors),function(author){return _c('span',{key:author},[_vm._v(" "+_vm._s(author)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }