import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=f368d9e8&scoped=true"
import script from "./mobile.vue?vue&type=script&lang=js"
export * from "./mobile.vue?vue&type=script&lang=js"
import style0 from "./mobile.vue?vue&type=style&index=0&id=f368d9e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f368d9e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAppBar,VAppBarNavIcon,VBtn,VExpandTransition,VImg,VList,VListItem,VListItemTitle,VMenu})
