const colors = {
  light: {
    base: "#6f71bc",
    card: "fffffd",
    background_card: "#dedede",
    text: {
      primary: "#333333"
    }
  },
  dark: {
    base: "#4b4d82",
    card: "#1e1e1e",
    background_card: "#383838",
    text: {
      primary: "#d9d9d9"
    }
  }
};

export default colors;
